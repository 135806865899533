<template>
  <div class="position-absolute external-help-position">
    <b-button
      :class="decisionSupportClicked ? '' : 'pulse'"
      pill
      variant="outline-secondary"
      size="sm"
      v-b-tooltip.hover
      :title="$t('danger.decisionSupport.toolTip')"
      :href="getDecisionSupportLink()"
      @click="
        () => {
          cookies.set(decisionSupportCookieName, true)
          decisionSupportClicked = true
        }
      "
      target="_blank"
    >
      <font-awesome-icon :icon="['fas', 'book-open-cover']" size="lg" />
      <font-awesome-icon
        :icon="['fas', 'up-right-from-square']"
        transform="shrink-6 right-1 down-5"
      />
    </b-button>
  </div>
</template>

<script setup lang="ts">
import { EnvironmentService } from '@/services/EnvironmentService'
import { i18n } from '@/plugins/i18n'
import { useCookies } from '@vueuse/integrations/useCookies'
import { ref } from 'vue'

const decisionSupportCookieName = 'danger.decisionSupport.clicked'
const cookies = useCookies([decisionSupportCookieName])

const decisionSupportClicked = ref(false)
decisionSupportClicked.value = cookies.get(decisionSupportCookieName)

const getDecisionSupportLink = () => {
  return `${EnvironmentService.getVariable('SLF_URL')}${i18n.global.t(
    'danger.decisionSupport.avalancheProblems'
  )}`
}
</script>

<style scoped>
.external-help-position {
  top: 0.5rem;
  right: 1.25rem;

  @media (min-width: 768px) {
    top: 1.25rem;
  }
}
.pulse {
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 122, 183, 1);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(51, 122, 183, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(51, 122, 183, 0);
  }
}
</style>
