import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-043b95a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-sm-3 d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DecisionSupportButton = _resolveComponent("DecisionSupportButton")!
  const _component_AreaSectorInput = _resolveComponent("AreaSectorInput")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_InfoLabel = _resolveComponent("InfoLabel")!
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group")!
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group")!
  const _component_wind_eurose = _resolveComponent("wind-eurose")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, {
    "footer-tag": "footer",
    "header-tag": "header"
  }, {
    header: _withCtx(() => [
      (_ctx.assFlag === false)
        ? (_openBlock(), _createBlock(_component_Header, {
            key: 0,
            ref: "header",
            title: _ctx.$t('ui.danger'),
            obs: _ctx.form,
            disableMap: _ctx.isStudyplotDanger,
            initialLocation: _ctx.location,
            adaptMap: _ctx.adaptMap
          }, null, 8, ["title", "obs", "disableMap", "initialLocation", "adaptMap"]))
        : _createCommentVNode("", true),
      (_ctx.assFlag === true)
        ? (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('ui.danger')), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { class: "text-left" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_button, {
                id: "dangerCancelButton",
                variant: "warning",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routeBack()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ui.cancel')), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_col, { class: "text-right" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_button, {
                id: "dangerSendButton",
                variant: "success",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-paper-plane" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('ui.send')), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DecisionSupportButton),
      _createElementVNode("div", null, [
        (_ctx.assFlag === true)
          ? (_openBlock(), _createBlock(_component_AreaSectorInput, {
              key: 0,
              ldId: _ctx.avalServiceId,
              disableSector: true,
              modelValue: _ctx.form.hierarchy,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.hierarchy) = $event))
            }, null, 8, ["ldId", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.assFlag === false)
          ? (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { md: "6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_group, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          hidden: "",
                          state: _ctx.locationState,
                          "aria-describedby": "locationFeedback"
                        }, null, 8, ["state"]),
                        _createVNode(_component_b_form_invalid_feedback, { id: "locationFeedback" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('msg.validObservationLocation')), 1)
                          ], undefined, true),
                          _: 1
                        })
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_b_row, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { md: "6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_group, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          hidden: "",
                          state: _ctx.hierarchyState,
                          "aria-describedby": "hierarchyFeedback"
                        }, null, 8, ["state"]),
                        _createVNode(_component_b_form_invalid_feedback, { id: "hierarchyFeedback" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('assessment.msg.validHierarchy')), 1)
                          ], undefined, true),
                          _: 1
                        })
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_group, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      hidden: "",
                      state: _ctx.contentState,
                      "aria-describedby": "contentFeedback"
                    }, null, 8, ["state"]),
                    _createVNode(_component_b_form_invalid_feedback, { id: "contentFeedback" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('msg.validMinimalContent')), 1)
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        })
      ]),
      _createVNode(_component_b_form, {
        onSubmit: _withModifiers(_ctx.onSubmit, ["stop","prevent"])
      }, {
        default: _withCtx(() => [
          (!_ctx.isStudyplotDanger)
            ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_group, {
                        label: _ctx.$t('observation.label.origin')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_select, {
                            id: "dangerOriginInput",
                            modelValue: _ctx.form.origin,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.origin) = $event)),
                            options: _ctx.origin
                          }, null, 8, ["modelValue", "options"])
                        ], undefined, true),
                        _: 1
                      }, 8, ["label"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: _ctx.$t('danger.label.problem'),
                        info: _ctx.$t('help.observation.danger.problem')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "dangerProblemInput",
                        modelValue: _ctx.form.problem,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.problem) = $event)),
                        options: _ctx.problem,
                        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetProblemDetail())),
                        state: _ctx.contentAState
                      }, null, 8, ["modelValue", "options", "state"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: _ctx.$t('danger.label.problemDetail'),
                        info: _ctx.$t('help.observation.danger.problemDetail')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_checkbox_group, {
                        id: "dangerProblemDetailPatternInput",
                        modelValue: _ctx.form.pattern,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.pattern) = $event)),
                        options: _ctx.pattern,
                        disabled: _ctx.form.problem !== 'DRY'
                      }, null, 8, ["modelValue", "options", "disabled"]),
                      _createVNode(_component_b_form_radio_group, {
                        id: "dangerProblemDetailAllDayInput",
                        modelValue: _ctx.form.allDay,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.allDay) = $event)),
                        options: _ctx.allDay,
                        disabled: _ctx.form.problem !== 'WET'
                      }, null, 8, ["modelValue", "options", "disabled"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_group, null, {
                            label: _withCtx(() => [
                              _createVNode(_component_InfoLabel, {
                                text: _ctx.$t('danger.label.aspectFrom'),
                                info: _ctx.$t('help.observation.danger.aspect')
                              }, null, 8, ["text", "info"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_select, {
                                id: "dangerAspectFromInput",
                                modelValue: _ctx.form.aspectFrom,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.aspectFrom) = $event)),
                                options: _ctx.aspect,
                                state: _ctx.aspectState,
                                "aria-describedby": "aspectFeedback"
                              }, null, 8, ["modelValue", "options", "state"]),
                              _createVNode(_component_b_form_invalid_feedback, { id: "aspectFeedback" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('msg.validAspectPlausibility')), 1)
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      }),
                      _createVNode(_component_b_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_group, null, {
                            label: _withCtx(() => [
                              _createVNode(_component_InfoLabel, {
                                text: _ctx.$t('danger.label.aspectTo'),
                                info: _ctx.$t('help.observation.danger.aspect')
                              }, null, 8, ["text", "info"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_select, {
                                id: "dangerAspectToInput",
                                modelValue: _ctx.form.aspectTo,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.aspectTo) = $event)),
                                options: _ctx.aspect,
                                state: _ctx.aspectState
                              }, null, 8, ["modelValue", "options", "state"])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_wind_eurose, { aspects: _ctx.selectedAspects }, null, 8, ["aspects"])
                      ])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: _ctx.$t('danger.label.altitudeValue'),
                        info: _ctx.$t('help.observation.danger.altitudeAbove')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "dangerAltitudeAboveInput",
                        modelValue: _ctx.form.altitudeAbove,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.altitudeAbove) = $event)),
                        options: _ctx.altitudeAbove,
                        state: _ctx.altitudeState,
                        "aria-describedby": "altitudeFeedback"
                      }, null, 8, ["modelValue", "options", "state"]),
                      _createVNode(_component_b_form_invalid_feedback, { id: "altitudeFeedback" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('msg.validAltitudePlausibility')), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: 
                  _ctx.$t('danger.label.altitudeValue') +
                  ' [' +
                  _ctx.$t('common.unit.meterAboveSeaLevel') +
                  ']'
                ,
                        info: _ctx.$t('help.observation.danger.altitude')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "dangerAltitudeInput",
                        modelValue: _ctx.form.altitudeValue,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.altitudeValue) = $event)),
                        options: _ctx.height,
                        state: _ctx.altitudeState
                      }, null, 8, ["modelValue", "options", "state"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: _ctx.$t('danger.label.level'),
                        info: _ctx.$t('help.observation.danger.level')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "dangerLevelInput",
                        modelValue: _ctx.form.level,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.level) = $event)),
                        options: _ctx.level,
                        state: _ctx.contentBState
                      }, null, 8, ["modelValue", "options", "state"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    label: _withCtx(() => [
                      _createVNode(_component_InfoLabel, {
                        text: _ctx.$t('danger.label.subdivision'),
                        info: _ctx.$t('help.observation.danger.subdivision')
                      }, null, 8, ["text", "info"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "dangerSubdivisionInput",
                        modelValue: _ctx.form.subdivision,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.subdivision) = $event)),
                        options: _ctx.subdivision,
                        disabled: _ctx.isSubdivisionDisabled
                      }, null, 8, ["modelValue", "options", "disabled"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { lg: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InfoLabel, {
                    text: _ctx.$t('danger.label.dangerConfidental'),
                    info: _ctx.$t('help.observation.danger.dangerConfidental'),
                    hide: "true"
                  }, null, 8, ["text", "info"]),
                  _createVNode(_component_b_form_checkbox, {
                    id: "dangerConfidental",
                    size: "sm",
                    modelValue: _ctx.form.confidential,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.confidential) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('danger.label.dangerConfidental')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["modelValue"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }, 8, ["onSubmit"])
    ], undefined, true),
    _: 1
  }))
}