import { ComponentPublicInstance } from 'vue'
import { ComposerTranslation, Path } from 'vue-i18n'

enum ToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
}

interface HandleOptions {
  titleKey?: Path
  titleValues?: Record<string, unknown>
  messageKey?: Path
  messageValues?: Record<string, unknown>
  autoHide?: boolean
}

export class InfoService {
  private static componentInstance?: ComponentPublicInstance
  private static t: ComposerTranslation

  static initialize(
    componentInstance: ComponentPublicInstance,
    t: ComposerTranslation
  ): void {
    this.componentInstance = componentInstance
    this.t = t
  }

  static showInfoMessage(options: HandleOptions): void {
    this.prepareToast(ToastType.Info, options, undefined)
  }

  static showSuccessMessage(options: HandleOptions): void {
    this.prepareToast(ToastType.Success, options, undefined)
  }

  static showWarningMessage(options: HandleOptions): void {
    this.prepareToast(ToastType.Warning, options, undefined)
  }

  static handleError(error: Error, options: HandleOptions = {}): void {
    console.error(error)
    this.prepareToast(ToastType.Danger, options, error)
  }

  private static getAlternateTitle(
    error: Error | undefined,
    type: ToastType
  ): string {
    if (error?.name) {
      return error.name
    }
    return type.toString().toUpperCase()
  }

  private static prepareToast(
    toastType: ToastType,
    options: HandleOptions,
    error: Error | undefined
  ): void {
    if (this.componentInstance) {
      const title = options.titleKey
        ? this.t(
            options.titleKey,
            this.getValueOrEmptyObject(options.titleValues)
          )
        : this.getAlternateTitle(error, toastType)
      const message = options.messageKey
        ? this.t(
            options.messageKey,
            this.getValueOrEmptyObject(options.messageValues)
          )
        : error?.message
      this.showToast(title, message, toastType, options.autoHide ?? false)
    }
  }

  private static showToast(
    title: string,
    message = '',
    type: ToastType,
    autoHide: boolean
  ): void {
    const compInstance = this.componentInstance as never as Vue
    compInstance.$bvToast.toast(message, {
      title,
      variant: type,
      solid: true,
      noAutoHide: !autoHide,
      append: true,
    })
  }

  static getValueOrEmptyObject(
    values?: Record<string, unknown>
  ): Record<string, unknown> {
    return values ? values : {}
  }
}
