import { App } from 'vue'
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'

// ACHTUNG: Wenn nicht diese ImportSyntax verwendet wird, wird das ganze '@fortawesome/proSolidSvgIcons' importiert!
//... { faSnowflake, faEdit } from '@fortawesome/proSolidSvgIcons' ==> So wird alles importiert!
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faBinoculars } from '@fortawesome/pro-solid-svg-icons/faBinoculars'
import { faBroadcastTower } from '@fortawesome/pro-solid-svg-icons/faBroadcastTower'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faCaretSquareLeft } from '@fortawesome/pro-solid-svg-icons/faCaretSquareLeft'
import { faCaretSquareRight } from '@fortawesome/pro-solid-svg-icons/faCaretSquareRight'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons/faHandHoldingUsd'
import { faInbox } from '@fortawesome/pro-solid-svg-icons/faInbox'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkedAlt'
import { faMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin'
import { faMountain } from '@fortawesome/pro-solid-svg-icons/faMountain'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faRulerVertical } from '@fortawesome/pro-solid-svg-icons/faRulerVertical'
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH'
import { faSnowflake } from '@fortawesome/pro-solid-svg-icons/faSnowflake'
import { faStreetView } from '@fortawesome/pro-solid-svg-icons/faStreetView'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons/faLayerGroup'
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faCloudRain } from '@fortawesome/pro-solid-svg-icons/faCloudRain'
import { faStroopwafel } from '@fortawesome/pro-solid-svg-icons/faStroopwafel'
import { faSmile } from '@fortawesome/pro-regular-svg-icons/faSmile'
import { faMeh } from '@fortawesome/pro-regular-svg-icons/faMeh'
import { faFrown } from '@fortawesome/pro-regular-svg-icons/faFrown'
import { faSignature } from '@fortawesome/pro-solid-svg-icons/faSignature'
import { faWind } from '@fortawesome/pro-solid-svg-icons/faWind'
import { faBomb } from '@fortawesome/pro-solid-svg-icons/faBomb'
import { faSortAmountUpAlt } from '@fortawesome/pro-solid-svg-icons/faSortAmountUpAlt'
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable'
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap'
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter'
import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons/faCrosshairs'
import { faSensorAlert } from '@fortawesome/pro-light-svg-icons/faSensorAlert'
import { faList } from '@fortawesome/pro-solid-svg-icons/faList'
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faUpRightFromSquare'
import { faShovel } from '@fortawesome/pro-regular-svg-icons/faShovel'
import { faBookOpenCover } from '@fortawesome/pro-solid-svg-icons/faBookOpenCover'

library.add(
  faArrowDown,
  faArrowUp,
  faBan,
  faBomb,
  faBinoculars,
  faBroadcastTower,
  faCalendarAlt,
  faCaretSquareLeft,
  faCaretSquareRight,
  faChevronDown,
  faChevronUp,
  faCloudRain,
  faCog,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFlagCheckered,
  faGlobe,
  faHandHoldingUsd,
  faInbox,
  faInfoCircle,
  faImages,
  faLayerGroup,
  faMap,
  faMapMarkedAlt,
  faMapPin,
  faMountain,
  faPaperPlane,
  faPlus,
  faRulerVertical,
  faSortAmountUpAlt,
  faSignOutAlt,
  faSlidersH,
  faSnowflake,
  faStroopwafel,
  faStreetView,
  faTable,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faTrashAlt as IconDefinition,
  faSignature,
  faWind,
  faSmile as IconDefinition,
  faMeh as IconDefinition,
  faFrown as IconDefinition,
  faFilter,
  faCrosshairs,
  faSensorAlert as IconDefinition,
  faList,
  faUpRightFromSquare,
  faShovel,
  faBookOpenCover
)

export function useFontawesomeIcons(app: App<Element>): void {
  app.component('font-awesome-icon', FontAwesomeIcon)
}
