import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/plugins/fontawesomeIcons.ts'
import '@/assets/global.css'
import {
  applyPolyfills as slfWorkbenchApply,
  defineCustomElements as slfWorkbenchDefine,
} from 'workbench-web-components/dist/loader'
import { InfoService } from './services/InfoService'
import { KeycloakAuthentication } from '@/plugins/KeycloakAuthentication'
import { usePrimeVueComponents } from '@/primeVueComponents'
import BootstrapVue from 'bootstrap-vue'
import { EnvironmentService } from '@/services/EnvironmentService'
import { i18n } from '@/plugins/i18n'
import { useFontawesomeIcons } from '@/plugins/fontawesomeIcons'
import { useStagedDocumentRemover } from '@/composables/useStagedDocumentRemover'
import { getOrInitRouterInstance } from '@/router/app.routes'

// Ansonsten funktionieren die Webkomponenten nicht mehr
// Siehe: https://github.com/ionic-team/stencil/issues/2804
/* eslint-disable  @typescript-eslint/no-explicit-any */
const WEBCOMPONENT_EVENTS_TO_LOWERCASE = {
  ael: (el: any, eventName: string, cb: any, opts: any) =>
    el.addEventListener(eventName.toLowerCase(), cb, opts),
  rel: (el: any, eventName: string, cb: any, opts: any) =>
    el.removeEventListener(eventName.toLowerCase(), cb, opts),
  ce: (eventName: string, opts: any) =>
    new CustomEvent(eventName.toLowerCase(), opts),
} as any
/* eslint-enable  @typescript-eslint/no-explicit-any */

async function startApp(): Promise<void> {
  slfWorkbenchApply().then(() => {
    slfWorkbenchDefine(window, WEBCOMPONENT_EVENTS_TO_LOWERCASE)
  })
  await EnvironmentService.initialize()
  await KeycloakAuthentication.initialize()

  const app = createApp(App)
  app.use(BootstrapVue).use(getOrInitRouterInstance()).use(i18n).use(store)
  useFontawesomeIcons(app)
  usePrimeVueComponents(app)

  store.commit('user/SET_USER')
  store.commit('profile/SET_AVALANCHE_SERVICE_MEMBERSHIP')

  const vm = app.mount('#app')
  InfoService.initialize(vm, i18n.global.t)
  useStagedDocumentRemover().initializeRemover(vm)
}
startApp().then()
